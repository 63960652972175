(function () {
    "use strict";

    // @ngInject
    function WorkspaceControllerCtor($scope, $stateParams, $translate, $injector, $q, $log, _, $window, $timeout, Enums, AbTestService, hotkeys,
                                     WorkspacesManager, WorkspaceFilesManager, UsersManager, EventsManager, ReferralsManager, CompaniesManager,
                                     AppStates, UIUtils, AnalyticsService, PopupMessageService, AppConfigService, ModalService, WorkspaceService,
                                     WorkspaceFileService, UiPersistenceService, EventService, OnboardingService, DeviceService, WebsocketHelperService,
                                     UserService, EmailPreviewManager, WorkflowManager, WorkflowService, $state, PhotosUploadManager, OnboardingBotManager,
                                     Constants, ngToast, $sce, ToastService, PretrialersService, FeaturesService, SchedulingService, RescheduleProjectService,
                                     InjectBrandingService, FlowsBetaUserTypeService, AccountsService, moment, OtamStatusService,
                                     FeatureRestrictionService, ReactModalService, StatsigService, ModelFactory, DatadogRUMService, CompanyService, AccountsManager, NewAutomationsService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WorkspaceController';

        this.AppStates = AppStates;
        this.$state = $state;
        this.$scope = $scope;
        this.WorkspacesManager = WorkspacesManager;
        this.EmailPreviewManager = EmailPreviewManager;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.AnalyticsService = AnalyticsService;
        this.EventsManager = EventsManager;
        this.EventService = EventService;
        this.UIUtils = UIUtils;
        this.PopupMessageService = PopupMessageService;
        this.$translate = $translate;
        this.ModalService = ModalService;
        this.WorkspaceService = WorkspaceService;
        this.UsersManager = UsersManager;
        this.CompaniesManager = CompaniesManager;
        this.ReferralsManager = ReferralsManager;
        this.WorkspaceFileService = WorkspaceFileService;
        this.UiPersistenceService = UiPersistenceService;
        this.OnboardingService = OnboardingService;
        this.DeviceService = DeviceService;
        this.Enums = Enums;
        this.AbTestService = AbTestService;
        this.ToastService = ToastService;
        this.CompanyService = CompanyService;
        this.AppConfigService = AppConfigService;
        this.DatadogRUMService = DatadogRUMService;
        this.ngToast = ngToast;
        this.$sce = $sce;
        this._ = _;
        this.$q = $q;
        this.$log = $log;
        this.$timeout = $timeout;
        this.workspaceTypePersHash = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.workspaceType) || {};
        this.fileTapTippyHash = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.fileCreatedFromTemplatesTooltip) || {};
        this.WebsocketHelperService = WebsocketHelperService;
        this.UserService = UserService;
        this.WorkflowManager = WorkflowManager;
        this.WorkflowService = WorkflowService;
        this.PhotosUploadManager = PhotosUploadManager;
        this.OnboardingBotManager = OnboardingBotManager;
        this.Constants = Constants;
        this.$stateParams = $stateParams;
        this.FeaturesService = FeaturesService;
        this.FeatureRestrictionService = FeatureRestrictionService;
        this.$window = $window;
        this.SchedulingService = SchedulingService;
        this.RescheduleProjectService = RescheduleProjectService;
        this.InjectBrandingService = InjectBrandingService;
        this.AccountsService = AccountsService;
        this.AccountsManager = AccountsManager;
        this.moment = moment;
        this.OtamStatusService = OtamStatusService;
        this.NewAutomationsService = NewAutomationsService;
        this.OtamStatusService.initializeAfterLogin();
        this.OtamStatusService.listenToMigration();

        this.deleteMemberLoading = {};
        this.currentUser = UsersManager.getCurrUser();
        this.isClient = this.currentUser.isClient();
        this.userIsVendor = this.currentUser.isVendor();
        this.isNewNotesExp = this.FeaturesService.isNewNotesByUser(this.currentUser);

        this.company = CompaniesManager.getCurrCompany();
        this.showRightPane = false;
        this.addingWorkspace = false;
        this.creatingWorkspace = false;
        this.appState.isFileRouteAndClientMode = false;

        this.deleteWorkflowIndicator = false;
        this.showMoreTools = false;
        this.workspaceTeamMembers = [];
        this.moreFilesexpanded = false;
        this.isMobileCreateFileToggleOpen = false;
        this.uploadLimit = 100;
        this.isCreateFileActive = false;
        this.workspaceClients = [];
        this.project = EventsManager.getEvent($stateParams.event_id);
        this.isOpenApplyAutomationModal = false;
        this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
        this.OtamStatusService.initializeAfterLogin();
        this.PretrialersService = PretrialersService;
        this.shouldShowCleanAtwsUI = this.PretrialersService.shouldShowCleanAtwsUI();
        this.showProjectClientFeedbackModal = false;

        this.showScheduleOptionsMenu = false;
        this.stepShowLimit = 5;
        this.isShowMoreClicked = false;
        this.FeatureRestrictionService = FeatureRestrictionService;
        this.automationsRestrictedFeature = this.FeatureRestrictionService.features.workflows;
        this.isAutomationsFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.automationsRestrictedFeature);
        this.ReactModalService = ReactModalService;
        this.StatsigService = StatsigService;
        this.ModelFactory = ModelFactory;
        this.isWorkspaceTourEnabled = false;
        this.participantsLoaded = false;
        this.isMobile = this.DeviceService.nxSmallBreakpoint();

        this.StatsigService.getExperimentConfigValue('new_collaborators_experience', 'isNewExperience', false)
        .then(function (isNewExperience) {
            this.shouldShowCollaboratorsProjectBanner = isNewExperience && this.currentUser.isAtwsPreTrialVendor();
        }.bind(this));


        // ng2react - schedule meeting button
        this.reactScheduleButton = false;
        this.StatsigService.isGateEnabled('react-schedule-in-ws').then(function (result) {
            this.reactScheduleButton = result;
        }.bind(this));

        // Indicates whether the project participants react component should show loading indicator.
        // Loading is ended after all angular digestion is ended (not when receiving props)
        this.reactComponentReadyAfterDigestion = false;

        // This is a prop use only for updates - i bump it when i want the react component to re-render (after
        // actions on workspace for example)
        this.rerender = 0;
        this.participantsLoaded = true;
        // Like in other ng2react projects, these actions are sent from angular to react in order to update
        // the angular context that might effect other parts in the page. The data is going only one way -
        // from angular to react
        this.projectParticipantsActionsForReact = {
            onRemoveParticipant: this.onRemoveParticipant.bind(this),
            handleAddNewClientCallback: this.handleAddNewClientCallback.bind(this),
            onAfterEditParticipant: this.onAfterEditParticipant.bind(this),
            openAddParticipantModal: this.openAddParticipantModal.bind(this)
        };
    

        // ng2react - Add team members to project
        this.addTeamMemberToProjectVariant = 'pending';
        this.StatsigService.isGateEnabled('ng2react-workspace-assign-team-members').then(function (isEnabled) {
            this.addTeamMemberToProjectVariant = isEnabled ? 'react' : 'angular';
        }.bind(this));

        if (this.currentUser && this.currentUser.hasCompanyAdminPermissions()) {
            this.StatsigService.isGateEnabled('project_client_feedback').then(function (isEnabled) {
                this.showClientFeedbackModal = isEnabled;
            }.bind(this));
        }

        // ng2react - Time tracker widget
        this.timeTrackerWidgetVariant = 'pending';
        this.StatsigService.isGateEnabled('ng2react-workspace-time-tracker-widget').then(function (isEnabled) {
            this.timeTrackerWidgetVariant = isEnabled ? 'react' : 'angular';
        }.bind(this)).catch(function () {
            this.timeTrackerWidgetVariant = 'angular';
        }.bind(this));

        this._initSessionTypes();
        this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.createNewFileButtonWorkspace,  'buttonCopy', 'Create new')
            .then(function (buttonCopy) {
                this.createNewFileButtonCopy = buttonCopy;
            }.bind(this));

        this.workspaceTourExperimentPromise = this.StatsigService.getExperimentConfigValue(this.Enums.StatsigExperiments.workspaceTour,  'is tour enabled', false, false)

        this.showCopyChangesForConsultants = this.currentUser.isConsultantCopyChangedEnabled();

        const unsubscribeFeatureRestrictions = $scope.$watch('workspaceVm.FeatureRestrictionService.featureRestrictions', function onFeatureRestrictionsChange() {
            this.isAutomationsFeatureRestricted = this.FeatureRestrictionService.isFeatureRestricted(this.automationsRestrictedFeature);
        }.bind(this), true);

        this.shouldShowAddTeamMembersToProject = function () {
            return (this.workspace && this.company && this.company.team_members && this.company.team_members.length > 0 && !this.workspace.isViewOnlyMode())
        };

        this.newautomationsVariant = 'pending';

        this.showAddTeamMembersToProject = this.shouldShowAddTeamMembersToProject()

        this.register(this.company, 'success', function () {
            this.showAddTeamMembersToProject = this.shouldShowAddTeamMembersToProject();
        }.bind(this));

        $scope.$on("$destroy", function () {
            unsubscribeFeatureRestrictions();
        });



        var self = this;

        if (this.currentUser.isVendor() && this.FlowsBetaUserTypeService.hasFiles) {
            var _hotkeys = hotkeys.bindTo($scope);
            _hotkeys.templateTitle = 'Workspace Shortcuts';
            ['proposal', 'invoice', 'brochure', 'questionnaire'].forEach(function (type) {
                var upperFileType = type.charAt(0).toUpperCase() + type.substr(1, type.length);
                var char = type.charAt(0);
                _hotkeys.add({
                    order: 1,
                    combo: 'shift+' + char,
                    description: 'Create ' + upperFileType,
                    callback: function createFileHotkeyCallback() {
                        this.AnalyticsService.track(
                            this,
                            this.AnalyticsService.analytics_events.hotkey_used,
                            {combo: 'shift+' + char, action: 'create ' + type}
                        );
                        this.createFileByType(type);
                    }.bind(this)
                });
            }.bind(this));
        }

        var setHasTeamMembers = function setHasTeamMembers() {
            this.hasTeamMembers = this.company.doesCurrUserHaveTeamMembers();
        }.bind(this);

        if (this.company.wasFullyFetched()) {
            setHasTeamMembers();
        } else {
            this.hasTeamMembers = false;
            this.registerOnce(this.company, 'success', setHasTeamMembers);
        }

        this.anySentFilesOnWorkspace = function anyEmailsOnWorkspace() {

            return this.workspace.workspace_files.some(function (file) {
                return file.isSent();
            });
        };

        this.anyPaidPaymentsOnWorkspace = function anyPaidPaymentsOnWorkspace() {

            return this.workspace.workspace_files.some(function (file) {
                return file.hasPaidPayments();
            });
        };


        this.anyPayableFilesOnWorkspace = function anyPayableFilesOnWorkspace() {
            return this.workspace.workspace_files.some(function (file) {
                return file.hasPayments();
            });
        };

        this.anyPayableAndSentFilesOnWorkspace = function anyPayableAndSentFilesOnWorkspace() {
            return this.workspace.workspace_files.some(function (file) {
                return (file.isSent() && file.hasPayments()) || file.hasPaidPayments();
            });
        };

        this.anyPayableAndSentFlowsOnWorkspace = function anyPayableAndSentFlowsOnWorkspace() {
            if (!this.workspace.flows) {
                return false;
            }
            return this.workspace.flows.some(function (flow) {
                return flow.status !== 'draft' && flow.has_invoice;
            });
        };

        this.workspaceId = $stateParams.workspace_id;
        this.scrollTo = $stateParams.scrollTo;
        var workspaceConfig = this.WorkspaceService.getWorkspaceConfig();
        this.newWorkspace = workspaceConfig.newWorkspace;
        this.requestProposal = workspaceConfig.requestProposal;
        this.hideAddParticipantModal = workspaceConfig.shouldHideAddParticipantsModal;
        this.WorkspaceService.clearWorkspaceConfig();
        
        this.refreshWorkspace = function (args) {
            this.workspace = WorkspacesManager.getWorkspace(this.workspaceId);
            this.WorkflowManager.getWorkflowInstance(this.workspaceId).then(function(workflow) {
                if (!workflow) {
                    return;
                }
                this.workflow = workflow;
                this.futureWorkflowSteps = workflow.workflow_steps.filter(function(step) {
                    return step.approval_status !== 'declined' && !step.workflow_step_action.completed
                })
            }.bind(this));
        };

        this.onFinishBotTyping = function () {
            this.onboardingBotTyping = false;
        }.bind(this);

        this.onStartBotTyping = function () {
            this.onboardingBotTyping = true;
        }.bind(this);

        //todo yona: why are we getting the old state params? we need to clear them!!

        this.gotWorkspace = function gotWorkspace() {
            this.AccountsService.verifyLoggedCompany(this.workspace.company_id, this.workspace.creator_account_id, 'workspace');

            this.hideVendorImage = true;
            this.hideClientIcon = true;
            this.isInViewMode = this.workspace.isViewOnlyMode();
            this.isWorkspaceOwner = this.workspace.creator_id === this.currentUser._id;

            this.InjectBrandingService.setBrandColorVars(this.workspace.creator_company_brand_color);
            this.showAddTeamMembersToProject = this.shouldShowAddTeamMembersToProject()

            if (!this.alreadyGotWorkspace) {
                //show the right pane if device mode
                this.showRightPane = $window.innerWidth > 800;
                var initialWidth = $window.innerWidth;
                angular.element($window).on("resize.workspaceController.resizingWindow", function () {
                    if (initialWidth !== $window.innerWidth) {
                        $scope.$applyAsync(function () {
                            self.isMobileRightPane = false;
                            self.showRightPane = $window.innerWidth > 800;
                        });
                    }
                });

            }
            //end right pane management.

            if (this.workspace.workspace_tab_info) {
                this.last_saved_workspace_name = this.workspace.workspace_tab_info.name;

                switch (this.workspace.workspace_tab_info.workspace_type) {
                    case "vendor":
                        this.hideVendorImage = false;
                        this.hideClientIcon = true;
                        break;
                    case "host":
                        this.hideVendorImage = true;
                        this.hideClientIcon = false;
                        break;
                    case "new":
                        this.hideVendorImage = true;
                        this.hideClientIcon = true;
                        break;
                }

                this.workspace_tab_icon_url = this.workspace.workspace_tab_info.icon_url;
            }

            if (this.showIntro === undefined && this.workspace.workspace_type) {
                this.showIntro = !this.workspaceTypePersHash[this.workspace.workspace_type + "_intro"];

                if (this.showIntro && this.workspace.workspace_type !== "general") {
                    this.workspaceTypePersHash[this.workspace.workspace_type + "_intro"] = true;
                    this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.workspaceType, this.workspaceTypePersHash);
                    this.openIntroModal().finally(function () {
                        if (this.isWorkspaceCreator()) {
                            this.addMembersToWorkspace(true);
                        }
                    }.bind(this));
                } else if (this.newWorkspace) { //no need to show intro. jump directly to add members dialog if needed

                    this.workspaceTourExperimentPromise.then((isWorkspaceTourEnabled) => {
                        const tourVisits = this.UiPersistenceService.getUiPersistence(
                            this.UiPersistenceService.keys.tourVisits, {}
                        );

                        const hasStartedWorkspaceTour = !!(tourVisits && tourVisits['WORKSPACE'] && tourVisits['WORKSPACE'].find(tourStepName => tourStepName === 'START'));
                        const canColideWithWorkspaceTour = isWorkspaceTourEnabled && !hasStartedWorkspaceTour;

                        // we do not want the add member to be opened again when workpsace is updated
                        this.newWorkspace = false;
                        $stateParams.newWorkspace = false;

                        if (this.CompaniesManager.getCurrCompany().isVenue()) {
                            this.dealWithVenueSpaces();
                        } else if (!this.hideAddParticipantModal && !canColideWithWorkspaceTour) {
                            this.addMembersToWorkspace(true);
                        }
                    });
                }
            }

            if (this.requestProposal) {
                // we do not want the add vendor to be opened again when workpsace is updated
                this.requestProposal = false;

                this._openAddVendorForRequestPropsoal();
            }

            if (!!$stateParams.action && !this.alreadyGotWorkspace) { //perform only once
                var analyticsArgs = {
                    workspace_id: this.workspace._id,
                    source: $stateParams.source
                };

                if ($stateParams.action === "refer" && this.allowRefer()) {
                    AnalyticsService.track(self.AnalyticsService.analytics_events.open_refer_workspace_from_url, analyticsArgs);
                    this.referWorkspace();
                } else if ($stateParams.action === "assign_team_member") {
                    AnalyticsService.track(self.AnalyticsService.analytics_events.open_assign_workspace_from_url, analyticsArgs);
                    this.assignToTeamMemberClicked();
                }
            }

            this.manageWorkspaceMembers =
                this.allowMenu() &&
                this.isVendor() &&
                !this.workspace.isViewOnlyMode() &&
                !this.workspace.isArchived();

            this.alreadyGotWorkspace = true;


            if (this.workspace.workflow_id) {
                this.workspaceHasWorkflowInstance = true;
            } else {
                this.workspaceHasWorkflowInstance = false;
            }
            this.isClientModeFunc();
            
            
            this.isTrialer = this.UsersManager.getCurrUser().startedTrialAfterDate('2024-04-14') // trialer in terms of started after release of new notes
            this.shouldShowNotesDiscoverability = !this.isClientMode && !this.isTrialer; // don't show in client portal, or if user is newer than notes feature release

            this.updateShouldApplyBranding();

            if (this.userIsVendor) {
                this.workspaceTeamMembers = this.getWorkspaceTeamMembers();
            }

            this.prepareStagesViewModel();

            this.workspaceClients = this._getWorkSpaceClients(this.workspace.members);
            this.event_name = this.workspace.getWorkspaceOrProjectName();

            this.reactComponentReadyAfterDigestion = true;
            this.rerender += 1;
        };

        this.refreshWorkspace();
        this.userCanReschedule = this.RescheduleProjectService.userCanReschedule(this.currentUser, this.workspace);

        this.isClientModeFunc();

        // ng2react - New automations widget
        if(this.isClientMode){
            this.newautomationsVariant = 'angular';
        } else {
            this.NewAutomationsService.isNewAutomationsEnabled().then(function (isEnabled) {
                this.newautomationsVariant = isEnabled ? 'react' : 'angular';
            }.bind(this));
        }

        this.workspace.on('success', this.gotWorkspace, this);
        this.workspace.on('error', function error(message) {
            self.AnalyticsService.trackError(self, self.AnalyticsService.analytics_events.fetch_workspace_error, message);
            self.$log.error('fetch workspace error', message);
            this.DatadogRUMService.addError(
                new Error("fetch workspace error: " + message),
                {
                    message
                }
            );
            if (self.alreadyGotWorkspace) {
                return;
            }

            // always show a general message. for yotam
            message = 'WORKSPACE._UNABLE_TO_LOAD_MESSAGE_';

            self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.info,
                message, function success() {
                    self.goToState(self.AppStates.root_core_navigation_home);
                });
        });

        this.isInViewMode = this.workspace.isViewOnlyMode();

        this.WorkspaceService.workspaceLoaded(this.workspaceId);

        $scope.$on('$destroy', function onDestroy() {
            angular.element($window).off("resize.workspaceController.resizingWindow");
            self.WorkspaceService.workspaceUnloaded(self.workspaceId);
        });

        this.reloadTabs = function reloadTabs() {
            this.tabs = [
                {tabName: 'Activity', sref: AppStates.root_core_navigation_event_workspace_feed, params: {}, isVisible: true},
                {tabName: 'Files', sref: AppStates.root_core_navigation_event_workspace_files, params: {}, isVisible: true},
                {tabName: 'Payments', sref: AppStates.root_core_navigation_event_workspace_payments, params: {}, isVisible: true},
                {tabName: 'Notes', sref: AppStates.root_core_navigation_event_workspace_notes, params: {}, isVisible: this.isNewNotesExp},
                {tabName: 'Details', sref: AppStates.root_core_navigation_event_workspace_details, params: {}, isVisible: true}
            ].filter(function(tab) {
                return tab.isVisible;
            });
        };
        this.reloadTabs();

        if (this.isVendor()) {
            this.tabs.forEach(function (tab) {
                tab.params.disableScrollToTop = true;
            });
        }

        this.updateWorkspaceName = function updateWorkspaceName() {
            var self = this;

            if (this.workspace.workspace_tab_info.name === this.last_saved_workspace_name ||
                this.workspace.workspace_tab_info.name.length === 0) {
                this.workspace.workspace_tab_info.name = this.last_saved_workspace_name;
                return;
            }

            var analyticsArgs = {
                workspace_name: this.workspace.workspace_tab_info.name
            };

            self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.user_updated_workspace_name, analyticsArgs);

            this.workspace.updateWorkspaceName().then(
                function success() {
                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.workspace_name_updated_successfully, analyticsArgs);
                },
                function error() {
                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.workspace_name_updated_error, analyticsArgs);
                }
            );
        };

        this._openAddMembers = function _openAddMembers(isNewWorkspace) {
            // should handle result here, since this is called from a few places in this file
            this.ReactModalService.openGlobalModal('AddClientToWorkspaceModalWrapper', {
                workspaceId: this.workspace._id,
                customFieldSchemas: this.company.custom_contact_fields
            }).then(function editSuccess(resp) {
                        var newClientUser = this.ModelFactory.newUserModel();
                        var clientUser = angular.extend(newClientUser, resp.user);
                        this.workspace.members.push(clientUser);
                        // this.workspace.members.push(resp.user);
                        this.WorkspacesManager.reloadWorkspaceInfo(this.workspace._id);

                        if (workspaceConfig.showLeadSourceToast && this.shouldShowLeadSourcesEditedToast()) {
                            this.showLeadSourcesEditedToast();
                        }
                    }.bind(this));
        };

        this._openAddVendorForRequestPropsoal = function _openAddVendorForRequestPropsoal() {
            var promise = this.WorkspaceService.addVendorToMemberList(this.workspace, this.workspace.members);
            promise.then(
                function usersAdded() {
                    if (self.workspace.getVendorsForRequestProposal(self.currentUser._id).length > 0) {
                        self._openEditorForRequestProposal();
                        self.WorkspacesManager.reloadWorkspaceInfo(self.workspace._id);
                    }
                });
        };

        this.createFileInProgress = false;
        this.workspaceUpdating = false;

        //Close left panel when entering workspace
        var appState = AppConfigService.appState();
        appState.leftPanelOpen = false;


        if (this.workspace.workflow_id) {
            this.workspaceHasWorkflowInstance = true;
            this.activeWorkflowInstanceId = this.workspace.workflow_id;
            this.activeWorkflowInstanceName = this.workspace.workflow_name;
        } else {
            this.workspaceHasWorkflowInstance = false;
        }

        // on url for showing tasks modal on mobile
        // needed for mobile app in-app browser
        if (this.$state.includes('root.core.navigation.event_wrapper.event.workspace.tasks')) {
            this.showTasksWorkspace = true;
        }


        this._shouldShowFileTabTippy();

        this.onCloseApplyAutomationModal = function onCloseApplyAutomationModal() {
            this.$scope.$applyAsync(function () {
                this.isOpenApplyAutomationModal = false;
                this.refreshWorkspace();
            }.bind(this));
        }.bind(this);
    }

    Controllers.WorkspaceController = Class(Controllers.BaseController, {

        constructor: WorkspaceControllerCtor,
        deleteWorkspace: function deleteWorkspace() {
            this.event = this.workspace.__event;
            var promise = this.WorkspacesManager.deleteWorkspace(this.workspace);
            promise.then(
                this.deleteWorkspaceSuccess.bind(this),
                this.deleteWorkspaceError.bind(this)
            );

            return promise;
        },

        showSpacesModal: function () {
            this.ModalService.openEventSpaces(this.workspace.__event, true).then(function (dismiss) {
                if (!dismiss) {
                    this.addMembersToWorkspace(true);
                }

            }.bind(this));
        },

        dealWithVenueSpaces: function () {

            this.CompaniesManager.getCompanySpaces(this.company).then(function () {
                var hasSpaces = this.company.company_spaces.length > 0;
                if (hasSpaces) {
                    this.showSpacesModal();
                } else {
                    var uiPersistenceData = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.spacesPopupViewed);
                    var timesSeenPopup = uiPersistenceData && uiPersistenceData.times ? uiPersistenceData.times : 0;

                    if (timesSeenPopup < 3) {
                        this.showSpacesModal();
                    } else {
                        // go to clients modal
                        this.addMembersToWorkspace(true);
                    }
                }

            }.bind(this));
        },

        referWorkspace: function referWorkspace() {
            return this.ReferralsManager.referWorkspace(this.workspace, this);
        },

        rescheduleProjectClicked: function rescheduleProjectClicked() {
            this.RescheduleProjectService.openRescheduleModal(this.project, this.workspace._id, false, 'workspace');
        },

        addWorkspaceClicked: function addWorkspaceClicked($event) {
            $event.preventDefault();
            $event.stopPropagation();
            this.addingWorkspace = true;
        },

        onCreateWorkspace: function onCreateWorkspace(workspaceType, source) {
            this.creatingWorkspace = true;
            var analyticsArgs = this.analyticsProperties();

            this.EventService.addWorkspaceToEventCommand(
                this.workspace.event._id,
                workspaceType,
                source,
                analyticsArgs,
                this)
                .finally(function onFinally() {
                    this.creatingWorkspace = false;
                }.bind(this));
        },

        onWorkspaceDropdownMenuClosed: function onWorkspaceDropdownMenuClosed() {
            this.addingWorkspace = false;
        },

        handleAddParticipantCallBack: function handleAddParticipantCallBack(user){
            if(user){
                this.workspace.members.push(user);
                this.WorkspacesManager.reloadWorkspaceInfo(this.workspace._id);
                this.rerender += 1;
            }
        },

        handleAddNewClientCallback: function handleAddNewClientCallback(user) {
            var newClientUser = this.ModelFactory.newUserModel();
            var clientUser = angular.extend(newClientUser, user);
            this.handleAddParticipantCallBack(clientUser);
        },

        getMemberById: function getMemberById(id) {
            const participantInAngular = this.workspace.members.find(function(curr) {
                return curr._id === id;
            });

            return participantInAngular;
        },

        openAddParticipantModal: function openAddParticipantModal(defaultTab) {
            this.ModalService.openAddWorkspaceParticipantModal(this.workspace, undefined, undefined, undefined, true, defaultTab).then(function editSuccess(resp) {
                this.handleAddParticipantCallBack(resp.user);
            }.bind(this));
        },

        onAfterEditParticipant: function(user, participantId) {
            const participantInAngular = this.getMemberById(participantId);
            if (participantInAngular) {
                participantInAngular.mixinFrom(user);
            }
            this.rerender += 1;
        },

        getPipelineStageName: function getPipelineStageName(stageNameKey) {
            var translationKey = 'PIPELINE.STAGES.' + stageNameKey;
            var translatedName = this.$translate.instant(translationKey, stageNameKey);
            if(translationKey.trim() === translatedName){
                return stageNameKey;
            } else {
                return translatedName;
            }
        },

        onRemoveParticipant: function onRemoveParticipant(participantId) {
            const removeMember = this.WorkspaceService.removeMemberFromWorkspaceWrapper.bind(this.WorkspaceService);
            const participantInAngular = this.getMemberById(participantId);

            const callback = function (status) {
                if (status === 'success') {
                    this.ToastService.showInfo({
                        content: participantInAngular.full_name + ' was removed from this project',
                        dismissOnTimeout: true,
                        dismissButton: true,
                        timeout:3000
                    });
                }
                this.rerender += 1;
            }.bind(this);

            removeMember(this.workspace, participantInAngular, callback);
        },

        deleteWorkspaceClicked: function deleteWorkspaceClicked() {
            var self = this;
            this.creatingWorkspace = true;

            var trackingData = {
                workspace_id: self.workspace._id
            };

            var deleteConfirmed = function deleteConfirmed() {
                self.workspaceUpdating = true;

                self.deleteWorkspace().then(
                    function success() {
                        self.workspaceUpdating = false;
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.workspace_deleted, trackingData);
                    },
                    function error() {
                        self.workspaceUpdating = false;
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.workspace_deleted_error, trackingData);
                    }
                );
            };

            var archiveConfirmed = function archiveConfirmed() {
                self.ModalService.openArchiveProjectModal(self.workspace._id, 'Workspace').then(function () {
                    self.ToastService.showSuccess({
                        contentTranslation: self.$translate.instant('WORKSPACE.ARCHIVE._TOOLTIP_', {project_name: self.workspace.getWorkspaceOrProjectName()}),
                        iconCssClass: 'icon icon-hb-nx-check-mark-circle-16 charcoal-60',
                        dismissOnTimeout: true,
                        dismissButton: true,
                        timeout:3000
                    });
                });
            };

            this.deletePopup = function deletePopup() {
                this.creatingWorkspace = false;

                if(this.project.workspaces.length > 1 ) {
                    self.$translate('WORKSPACE.DELETE._FULL_MESSAGE_PLURAL_WORKSPACES_', {workspace_name: self.workspace.workspace_tab_info.name})
                        .then(function translateResolved(text) {
                            self.PopupMessageService.showConfirm(self.PopupMessageService.severityTypes.warning,
                                text,
                                deleteConfirmed,
                                undefined,
                                'WORKSPACE.DELETE._YES_PLURAL_WORKSPACES_',
                                'WORKSPACE.DELETE._NO_',
                                'WORKSPACE.DELETE._TITLE_PLURAL_WORKSPACES_',
                                undefined,
                                true);
                        });
                }
                else {
                    self.$translate('WORKSPACE.DELETE._FULL_MESSAGE_', {workspace_name: self.workspace.getWorkspaceOrProjectName()})
                        .then(function translateResolved(text) {
                            self.PopupMessageService.showConfirm(self.PopupMessageService.severityTypes.warning,
                                text,
                                deleteConfirmed,
                                undefined,
                                'WORKSPACE.DELETE._YES_',
                                'WORKSPACE.DELETE._NO_',
                                'WORKSPACE.DELETE._TITLE_',
                                undefined,
                                true);
                        });
                }
            };

            this.archivePopup = function archivePopup() {
                this.creatingWorkspace = false;

                self.$translate('WORKSPACE.ARCHIVE._FULL_MESSAGE_', {workspace_name: self.workspace.getWorkspaceOrProjectName()})
                    .then(function translateResolved(text) {
                        self.PopupMessageService.showConfirm(self.PopupMessageService.severityTypes.info,
                            text,
                            archiveConfirmed,
                            undefined,
                            'WORKSPACE.ARCHIVE._YES_',
                            'WORKSPACE.ARCHIVE._CANCEL_',
                            'WORKSPACE.ARCHIVE._TITLE_',
                            undefined,
                            true);
                    });
            };

            this.WorkspacesManager.isDeletable(self.workspace._id).then(function success(res) {
                self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.delete_workspace, {workspace_id: self.workspace._id, is_deletable: res.data.is_deletable});

                if(res.data.is_deletable) {
                    self.deletePopup();
                }
                else {
                    self.workspace.is_deletable = false;
                    self.archivePopup();
                }
            });
        },

         deleteWorkspaceSuccess: function deleteWorkspaceSuccess(resp) {
             this.ToastService.showSuccess({
                 iconCssClass: 'icon icon-hb-nx-check-mark-circle-16 charcoal-60',
                 content: this.$translate.instant('WORKSPACE.DELETE._TOOLTIP_',
                     {project_name: this.workspace.getWorkspaceOrProjectName(), stage_name: this.getCurrentStageName(this.workspace)}),
                 dismissOnTimeout: true,
                 timeout: 5000,
                 dismissButton: true
             });

            if (resp.status === 404) {
                this.EventService.clearLastEvent(this.currentUser);
                // no event left
                this.goToState(this.AppStates.root_core_navigation_pipeline);
                return;
            }

            // go to other workplace in event
            var workspaceId = resp.workspaces[0]._id;
            this.goToState(this.AppStates.root_core_navigation_event_workspace_feed, {workspace_id: workspaceId});
        },

        deleteWorkspaceError: function deleteWorkspaceError() {
            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error,
                'WORKSPACE.DELETE._ERROR_');
        },

        // =====================
        // Create File
        // =====================
        createFileByType: function createFileByType(fileType) {

            // if file creation in progress we step out...
            if (this.createFileInProgress) {
                return;
            }

            var isMobile = this.DeviceService.nxSmallBreakpoint() || this.DeviceService.isIOS() || this.DeviceService.isAndroid();
            if ((isMobile && fileType !== 'flow') || fileType === 'timeline') {
                this.createEmptyFileByType(fileType);
            } else {
                this.openCreateFileModal(fileType);
            }
        },

        createEmptyFileByType: function createEmptyFileByType(fileType) {

            // if file creation in progress we step out...
            if (this.createFileInProgress) {
                return;
            }

            // call create
            var promise = this.WorkspaceFileService.createFile(fileType, this.workspace, null, null, null, false);
            this.createFileInProgress = true;
            promise.finally(function createFileDone() {
                this.createFileInProgress = false;
            }.bind(this));
        },

        openCreateFileModal: function openCreateFileModal(fileType) {
            this.ModalService.openStartFromFileWizardModal({type: fileType}, this.workspace);
        },

        createRequestProposal: function createRequestProposal() {
            var vendors = this.workspace.getVendorsForRequestProposal(this.currentUser._id);

            // no vendors, but we have clients
            if (vendors.length < 1 && this.workspace.members.length > 1) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.info,
                    'WORKSPACE.REQUEST_PROPOSAL._NO_VENDOR_');
                return;
            }

            // empty workspace, we allow to continue the flow from here.
            if (vendors.length < 1) {
                this._openAddVendorForRequestPropsoal();
                return;
            }

            // we have a vendor, write the email
            this._openEditorForRequestProposal();
        },

        // =====================
        // ACTIONS
        // =====================

        assignToTeamMemberClicked: function assignToTeamMemberClicked() {
            this.WorkspaceService.assignWorkspaceToTeamMembers(this.workspace, this.company, this.workspace.creator);
        },

        // =====================
        // MENU
        // =====================

        allowDelete: function allowDelete() {
            return this.workspace.allowDelete();
        },

        allowRefer: function allowRefer() {
            return this.isVendor() && this.workspace.members[1]; //this is a more efficient way to check if members length >= 2
        },

        allowMenu: function allowMenu() {
            return (this.currentUser.isVendor() && !this.workspace.isViewOnlyMode());
        },

        isCompanyOwnerAndNotPartOfWS: function () {
            var isMember = this.workspace.members.find(function (member) {
                return member._id === this.currentUser._id;
            }.bind(this));
            return this.currentUser.isCompanyOwner() && !isMember;
        },

        isVendor: function isVendor() {
            return this.UIUtils.isVendor();
        },

        isWorkspaceCreator: function isWorkspaceCreator(user) {
            if (!user) {
                user = this.currentUser;
            }

            return this.workspace.creator_id === user._id;
        },

        // UI function
        getWorkspaceState: function getWorkspaceState() {
            return this.UIUtils.generateClassForWorkspaceState(this.workspace);
        },


        // Private functions
        //==================

        sendToUser: function sendToUser(props) {
            var user = props.users;
            var subject = props.subject;
            var body = props.body;
            var isLast = props.isLast;

            var email = this.currentUser.request_proposal_email;

            var promise = this.WorkspacesManager.sendRequestProposalToUser(this.workspace, user, subject, body, email.email_type);
            return promise;
        },

        previewSendToUser: function previewSendToUser(user, subject, body) {
            var email = this.currentUser.request_proposal_email;

            var promise = this.EmailPreviewManager.getWorkspaceFileEmailPreview(this.workspace, user, subject, body, email.email_type);
            return promise;
        },

        _openEditorForRequestProposal: function _openEditorForRequestProposal() {
            // Get the email 'template'
            var email = this.currentUser.request_proposal_email;
            var myId = this.currentUser._id;
            var config = {editorType: "request proposal"};

            var users = this.workspace.getVendorsForRequestProposal(myId);

            var options = {
                users: users,
                subject: email.subject,
                body: email.html_body,
                generalFiles: email.file_attachments,
                imageFiles: email.image_attachments,
                senderFunction: this.sendToUser.bind(this),
                config: config,
                previewFunction: this.previewSendToUser.bind(this),
                showScheduleOption: false
            };
            
            this.WorkspaceFileService.showEmailEditorModal(options);
        },

        beforeDestroy: function beforeDestroy() {
            this.WorkspaceService.cancelActiveIntervals();
        },

        analyticsProperties: function analyticsProperties() {
            return this.WorkspaceService.generateAnalyticsProps(this.workspace);
        },

        addTeamMember: function addTeamMember(isNewWorkspace) {
            var userList = this.company.team_members.slice();
            userList.push(this.company.owner);

            this.ModalService.openAddTeamMemberToWorkspaceModal(this.workspace, userList, true, isNewWorkspace);
        },

        openIntroModal: function () {
            return this.ModalService.openWorkspaceTypeIntroModal(this.workspace);
        },


        addMembersToWorkspace: function addMembersToWorkspace(isNewWorkspace) {
            if (this.workspace.workspace_type === "team" && this.company.doesCurrUserHaveTeamMembers()) {
                this.addTeamMember(isNewWorkspace);
            } else {
                this._openAddMembers(isNewWorkspace);
            }
        },

        editMember: function (member) {
            // this.deleteMemberLoading[member._id] = true;
            this.UserService.editContact(member);
        },

        addParticipant: function addParticipant() {
            this.showAddMemberForm = 'true';
        },

        confirmExitAddParticipantForm: function confirmExitAddParticipantForm() {
            this.showAreYouSure = false;
            this.showAddMemberForm = false;
            if (this.isMobileRightPane) {
                this.showRightPane = false;
            }
        },

        exitAddParticipantForm: function exitAddParticipantForm(confirm) {
            if (confirm) {
                this.showAreYouSure = true;
            } else {
                this.confirmExitAddParticipantForm();
            }
        },

        showAsideAddMember: function showAsideAddMember() {
            this.showProjectAside = true;
            this.showAddMember = true;
        },

        showTasks: function showTasks() {
            this.showProjectAside = true;
            this.showTasksWorkspace = true;
        },

        closeAside: function () {
            this.showProjectAside = false;
            this.showAddMember = false;
            this.showTasksWorkspace = false;
        },

        handleCloseMobilePaneClick: function handleCloseMobilePaneClick() {
            this.isMobileRightPane = false;
        },

        getParticipantsLabel: function getParticipantsLabel() {
            return this.OnboardingService.isOnboardingActive() ? 'ADD_USER_DIALOG._ADD_USER_TRIALER_' : 'ADD_USER_DIALOG._ADD_USER_';
        },

        openAddWorkflowModal: function openAddWorkflowModal() {
            if (this.workspace.members.length === 1) {
                this.PopupMessageService.showAlert(
                    this.PopupMessageService.severityTypes.error,
                    'WORKFLOW.ALERTS._ADD_WORKFLOW_MEMBERLESS_'
                )
            } else {
                if (this.isAutomationsFeatureRestricted) { 
                    this.FeatureRestrictionService.handleRestrictedFeatureClick(this.automationsRestrictedFeature);
                    return;
                }
                this.isOpenApplyAutomationModal = true; 
            }
        },

        removeWorkflowInstanceConfirm: function removeWorkflowInstanceConfirm() {
            this.PopupMessageService.showConfirm(this.PopupMessageService.severityTypes.warning,
                'WORKFLOW.ALERTS._REMOVE_WORKFLOW_FROM_WORKSPACE_', this.removeWorkflowInstance.bind(this));
        },

        removeWorkflowInstance: function removeWorkflowInstance() {
            this.deleteWorkflowIndicator = true;

            this.WorkflowManager.removeWorkflowInstanceFromWorkspace(this.workspace._id, this.workspace.workflow_id)
                .then(function success(resp) {
                    this.workspaceHasWorkflowInstance = false;
                    this.deleteWorkflowIndicator = false;
                    this.workspace.workflow_id = null;
                    this.workspace.workflow_name = null;
                    this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.workflow_remove_from_workspace);

                }.bind(this))
                .catch(function fail(resp) {
                }.bind(this))
                .finally(function finallyHandler() {
                }.bind(this));
        },

        getWorkflowWorkspaceInstance: function getWorkflowWorkspaceInstance() {
            this.activeWorkspaceWorkflowCopy = this.WorkflowService.getWorkspaceWorkflowObj();

            if (this.activeWorkspaceWorkflowCopy._id) {
                this.workspaceHasWorkflowInstance = true;
                this.workspace.workflow_id = this.activeWorkspaceWorkflowCopy._id;
                this.workspace.workflow_name = this.activeWorkspaceWorkflowCopy.title;
            } else {
                this.workspaceHasWorkflowInstance = false;
            }
        },

        viewFullViewWorkflowInstance: function viewFullViewWorkflowInstance($event, shouldOpenInEditMode) {
            $event.stopPropagation();
            this.$state.go(this.AppStates.root_core_automations_apply, {
                workspace_id: this.workspace._id,
                shouldOpenInEditMode: shouldOpenInEditMode
            });
        },

        isClientModeFunc: function isClientModefunc() {
            // User is a client, or the workspace owner, or in a different company than the workspace owner
            var currUser = this.UsersManager.getCurrUser();
            this.isClientMode = currUser &&
                (currUser.isClient() ||
                    (angular.isDefined(this.workspace.company_id) &&
                        angular.isDefined(currUser.company._id) &&
                        currUser.company._id !== this.workspace.company_id));
        },

        viewMoreTools: function viewMoreTools() {
            this.showMoreTools = !this.showMoreTools;
        },

        allowWorkspaceActions: function allowWorkspaceActions() {
            return this.allowMenu() &&
                this.isVendor() &&
                (this.currentUser.isCompanyOwner() || !this.workspace.isViewOnlyMode()) &&
                !this.workspace.isArchived();
        },

        getWorkspaceTeamMembers: function getWorkspaceTeamMembers() {
            var teamMembersInWorkspace = [];
            var companyTeam = [];
            var workspaceMembersIds = [];

            if (this.company.team_members) {
                companyTeam = this.company.team_members.map(function (teamMember) {
                    return teamMember._id;
                });
            }

            if (this.workspace.members) {
                workspaceMembersIds = this.workspace.members.map(function (member) {
                    return member._id;
                });
            }

            // company.team_members excludes the owner
            companyTeam.push(this.company.owner._id);

            // owner should always be part of the workspace's members if logged in
            var workspaceMembers = this.workspace.members.slice();
            if (this.currentUser.isCompanyOwner() && workspaceMembersIds.indexOf(this.company.owner._id) === -1) {
                workspaceMembers.push(this.company.owner);
            }

            workspaceMembers.forEach(function (workspaceMember) {
                var teamMember = companyTeam.indexOf(workspaceMember._id) > -1;
                if (teamMember) {
                    if (this.currentUser._id === workspaceMember._id) {
                        teamMembersInWorkspace.unshift({first_name: 'You', _id: workspaceMember._id});
                    } else {
                        teamMembersInWorkspace.push({first_name: workspaceMember.first_name, _id: workspaceMember._id});
                    }
                }
            }.bind(this));

            return teamMembersInWorkspace;
        },

        isTrackingDisable: function isTrackingDisable(workspace) {
            return (workspace && workspace.workspace_pipeline_data && workspace.workspace_pipeline_data.disable_tracking);
            //return (workspace.wasFullyFetched() && workspace.workspace_pipeline_data.disable_tracking);
        },

        prepareStagesViewModel: function prepareStagesViewModel() {
            if (this.company.user_pipeline_stages) {
                this.stages = this.WorkspaceService.prepareStagesViewModel(this.company.user_pipeline_stages);
            }
        },

        getCurrentStageName: function getCurrentStageName(workspace) {

            if (this.isTrackingDisable(workspace)) {
                return;
            }

            if (!workspace.isViewOnlyMode() && workspace.workspace_pipeline_data && this.stages) {
                this.currentStage = this._.findWhere(this.stages, {_id: workspace.workspace_pipeline_data.current_stage.stage_id});
                return this.currentStage && this.currentStage.name;
            }
        },

        isTabActive: function isTabActive(tab) {
            return tab.sref === this.$state.current.name;
        },

        isRouteActive: function isRouteActive(root) {
            return this.$state.current.name && this.$state.current.name.indexOf(root) !== -1;
        },

        isNotesRouteActive: function isNotesRouteActive() {
            return this.isNewNotesExp && this.isRouteActive('notes');
        },

        updateShouldApplyBranding: function updateShouldApplyBranding() {
            this.shouldApplyBranding = (!this.isVendor() && !this.workspace.isViewOnlyMode() && !this.workspace.isArchived() && this.uploadEnabled());
        },
        openUploadFileModal: function openUploadFileModal() {
            if (!this.uploadEnabled()) {
                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'Project attachments limit reached.');
                return;
            }

            this.ModalService.openAttachAssetModal(false, this.currentUser.isVendor(), true, 'Upload',
                true, true, false, null, false, false, '', '', '', 'MEDIA_MODAL.TITLE._ATTACH_IMG_OR_FILE_', '', this.shouldApplyBranding).then(function (filesList) {
                var promises = [];
                var files = [];

                if (this.workspace.totalAttachments() + filesList.length > this.uploadLimit) {
                    var rejected = this.$q.reject({
                        error_message: 'WORKSPACE.FILES._ATTACHMENTS_LIMIT_REACHED_',
                        from_client: true
                    });
                    promises.push(rejected);
                }

                filesList.forEach(function (file) {
                    var fileType = (/\.(gif|jpe?g|tiff|png)([?#].*)?/i.test(file.url || file.name)) ? "image" : "file";

                    if (!file.url) {
                        //start to upload these files right away. save the promises of the uploads
                        //because the send message will have to wait for them to finish till they upload
                        var model = {
                            instance: this.workspace,
                            type: 'workspace_attachment',
                            dontUpdateServer: true
                        };

                        this.PhotosUploadManager.getUpdatedModelData(model);
                        var fileUploadPromise = this.PhotosUploadManager.uploadPhoto([file], model, this);

                        promises.push(fileUploadPromise);
                    }

                    var fileName = file.name || file.url.split('\\').pop().split('/').pop();
                    var fileExt = fileName.split('.')[1];

                    this.workspace.attachments = this.workspace.attachments || {images: [], files: []};

                    if (fileType === 'image') {
                        this.workspace.attachments.images.unshift({
                            inUpload: true,
                            url: file.url,
                            dataUrl: file.dataUrl
                        });
                    } else {
                        this.workspace.attachments.files.unshift({
                            inUpload: true,
                            name: fileName,
                            file_type: fileExt
                        });
                    }

                    files.push(file);
                }.bind(this));

                this.$q.all(promises).then(function () {
                    var filesData = this._.map(files, function (file) {

                        if (file.url || file.file_url) {
                            var translatedItem = {
                                url: file.url || file.file_url,
                                asset_name: file.asset_name || file.file_name,
                            };
                            if (file.cloudinary_public_id) {
                                translatedItem.cloudinary_public_id = file.cloudinary_public_id;
                            }

                            return translatedItem;
                        } else if (file.s3Response) {
                            return {
                                url: file.s3Response.url
                            };
                        }
                    });

                    this.WorkspacesManager.addAttachments(this.workspace, filesData).then(function success() {
                        this.AnalyticsService.trackSuccess(this, this.AnalyticsService.analytics_events.workspace_attachments_uploaded, {
                            filesUploaded: files.length,
                            workspace_id: this.workspace._id
                        });
                    }.bind(this));

                }.bind(this))
                    .catch(function (resp) {
                        if (resp.from_client) {
                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, {
                                translationId: resp.error_message,
                                interpolateParams: {
                                    limit: this.uploadLimit
                                }
                            });
                        } else if (resp.data) {
                            var data = resp.data;
                            if (data.error_type === 'HBWorkspaceAttachmentsLimitError') {
                                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message);
                            } else {
                                this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._UNEXPECTED_');
                            }
                        }

                        this.workspace.attachments.files = this._.reject(this.workspace.attachments.files, function (file) {
                            return file.inUpload;
                        });
                        this.workspace.attachments.images = this._.reject(this.workspace.attachments.images, function (file) {
                            return file.inUpload;
                        });

                    }.bind(this));
            }.bind(this));
        },

        uploadEnabled: function uploadEnabled() {
            return this.workspace.totalAttachments() < this.uploadLimit;
        },

        getSessionType: function getSessionType(sessionType) {
            var sessionTypeObj = this.Constants.MeetingTypes.find(function (type) {
                return type.key === sessionType;
            }.bind(this));
            return sessionTypeObj.label;
        },

        getWindowType: function getWindowType(windowType) {
            var windowTypeObj = this.Constants.WindowTypes.find(function (type) {
                return type.key === windowType;
            }.bind(this));
            return windowTypeObj.label;
        },

        _initSessionTypes: function _initSessionTypes() {
            this.sessionTypes = [];
            if (this.SchedulingService.isSchedulingEnabled(this.currentUser)) {
                this.currentUser.getSessions(null, null, true, false).then(function (resp) {
                    this.sessionTypes = resp;
                }.bind(this));
            }
        },

        onGoToCalendarClick: function onGoToCalendarClick() {
            // this is to not use <a> tag in the html so the entire container will be clickable
            var href = this.$state.href(this.AppStates.root_core_navigation_calendar, {}, {absolute: true});
            this.$window.open(href, '_blank');
        },

        openCreateMeetingModal: function openCreateMeetingModal(sessionConfig) {
            sessionConfig = sessionConfig || {};

            var meetingTitleParts = [];

            if (this.workspaceClients.length) {
                meetingTitleParts.push(this.workspaceClients[0].full_name);
            }

            if (sessionConfig.session_name) {
                meetingTitleParts.push(sessionConfig.session_name);
            }

            var meetingModalOptions = {
                isCreateMeeting: true,
                type: sessionConfig.session_type,
                workspace_id: this.workspace._id,
                event_id: this.workspace.event_id,
                event_name: this.workspace.event_name,
                title: meetingTitleParts.join(' - '),
                description: sessionConfig.session_description,
                location: sessionConfig.meeting_location,
                duration: sessionConfig.session_duration,
                session_config_id: sessionConfig._id,
                video_conference_type: sessionConfig.video_conference_type,
                phone_call_number: sessionConfig.phone_call_number,
                video_meeting_link: sessionConfig.video_meeting_link
            };

            this.ModalService.openCalendarMeetingModal(meetingModalOptions).then(function success(calendarItemAdded) {
                this.refreshWorkspace();
            }.bind(this));
        },

        closeScheduleOptionsMenu: function closeScheduleOptionsMenu() {
            if (this.showScheduleOptionsMenu) {
                this.toggleScheduleOptionsMenu();
            }
        },

        toggleScheduleOptionsMenu: function openScheduleModal() {
            this.showScheduleOptionsMenu = !this.showScheduleOptionsMenu;
        },

        _shouldShowFileTabTippy: function _shouldShowFileTabTippy() {
            if (this.fileTapTippyHash.seen) {
                return;
            }

            if (this._isFileCreatedFromTemplates()) {
                this.fileTapTippyVisible = true;
                var fileName = this.$stateParams.origin[1];
                if (fileName) {
                    fileName = this.WorkspaceFileService.formatFileTypeToDisplayName(fileName);
                } else {
                    fileName = 'file';
                }
                this.fileTapTippyTitle = 'Looking for your ' + fileName + '?';
                this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.fileCreatedFromTemplatesTooltip, {seen: true});
                this._showFileTippy();
            }
        },

        _showFileTippy: function _showFileTippy() {
            this.$timeout(function () {
                //using $timeout because the document needs to be loaded.
                document.querySelector('#file-tab__tippy--file-from-template')._tippy.show();
            }.bind(this), 2000);
        },

        _isFileCreatedFromTemplates: function _isFileCreatedFromTemplates() {
            if (!!this.$stateParams.origin) {
                return this.$stateParams.origin[0] === this.Enums.createFileOriginType.template;
            }

            return false;
        },

        shouldShowLeadSourcesEditedToast: function shouldShowLeadSourcesEditedToast() {
            return !this.DeviceService.checkIfMobileSize() && this.currentUser.isCompanyOwner() && this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.showLeadSourceToast, {show: true}).show;
        },

        showLeadSourcesEditedToast: function _showLeadSourcesEditedToast() {
            var toastIcon = '<span class="icon icon-hb-nx-bell"></span>';
            var toastTitle = this.$translate.instant('LEADSOURCE.TOAST._TITLE_');
            var toastSubTitle = this.$translate.instant('LEADSOURCE.TOAST._SUB_TITLE_');
            var toastCtaUpdateText = this.$translate.instant('LEADSOURCE.TOAST._UPDATE_NOW_');
            var toastTextIntro = this.$translate.instant('LEADSOURCE.TOAST._YOU_CAN_');
            var toastTextReview = this.$translate.instant('LEADSOURCE.TOAST._REVIEW_');

            var dismiss = '<a ng-click="workspaceVm.dissmissLeadSourceToast()" class="lead-sources-toast__close"><span class="icon icon-hb-nx-close-16"></span></a>';
            var toastCtaUpdate = '<a class="lead-sources-toast__cta" ng-click="workspaceVm.updateContactForm()" >' + toastCtaUpdateText + '</a>';
            var toastCtaReview = '<div>' + toastTextIntro + '<a ng-click="workspaceVm.reviewContactForm()" >' + toastTextReview + '</a>' + toastSubTitle + toastCtaUpdate + '</div>';
            var toastContent = '<div class="lead-sources-toast__content">' + '<div><strong>' + toastTitle + '</strong>' + toastCtaReview + '</div>';

            this.toastLeadSourceContent = '<div class="lead-source__toast">' + toastIcon + toastContent + dismiss + '</div>';

            this.toastLeadSource = this.ngToast.create({
                content: this.$sce.trustAsHtml(this.toastLeadSourceContent),
                className: 'lead-source_toast alert alert-info',
                dismissOnTimeout: false,
                compileContent: this.$scope
            });

            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.lead_source_toast__shown, {
                source: "workspace",
                secondary_source: this.source
            });
        },

        reviewContactForm: function reviewEditedLeadSources() {
            this.ngToast.dismiss(this.toastLeadSource);
            this.$state.go(this.AppStates.root_core_navigation_templatesEditor, {templateModelKey: 'contactForm'});
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.lead_source_toast__closed, {
                source: "workspace",
                secondary_source: this.source,
                contact_form: "review"
            });
            this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.showLeadSourceToast, {show: false});
        },

        updateContactForm: function updateContactForm() {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.lead_source_toast__closed, {
                source: "workspace",
                secondary_source: this.source,
                contact_form: "update"
            });
            this.CompaniesManager.publishContactFormsLeadSource(this.company)
                .then(function success() {
                    this.ngToast.dismiss(this.toastLeadSource);
                    this._showConfirmationSavedContactFormToast();
                    this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.showLeadSourceToast, {show: false});
                }.bind(this));
        },

        dissmissLeadSourceToast: function dissmissLeadSourceToast() {
            this.ngToast.dismiss(this.toastLeadSource);
        },

        _showConfirmationSavedContactFormToast: function _showConfirmationSavedContactFormToast() {
            this.$timeout(function () {
                this.ToastService.showSuccess({
                    contentTranslation: 'LEADSOURCE.TOAST._CONFIRMATION_SAVED_',
                    iconCssClass: 'icon icon-hb-nx-check-mark-circle-16',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            }.bind(this), 1000);
        },

        gotoBusinessTools: function gotoBusinessTools() {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.atws_banner_cta, {source: "workspace"});
            this.$state.go(this.AppStates.root_core_navigation_business_tools);
        },

        _getWorkSpaceClients: function _getWorkSpaceClients(participants) {
            return this._.filter(participants, function (participant) {
                if (participant._id !== this.currentUser._id && participant.company._id !== this.workspace.company_id) {
                    return participant;
                }
            }.bind(this));
        },

        openClientPortalModal: function openClientPortalModal() {
            var clientPortalLink = this.CompanyService.companyProfileUrl(this.company);
            this.ModalService.openClientPortalSendLinkModal(this.workspaceClients, clientPortalLink);
        },

        shouldShowSendClientPortalLink: function shouldShowSendClientPortalLink() {
            return this.workspaceClients.length &&
                ((this.company.company_url_prefixes &&
                    this.company.company_url_prefixes.length));
        },

        openNotesFullScreen: function openNotesFullScreen() {
            this.ModalService.openProjectDetailsNotesModal(this.project, this.currentUser.company);
        },

        onCreateFlow: function onCreateFlow() {
            return this.ModalService.openCreateFlowModal(this.project, this.currentUser.company);
        },

        showMoreStepsText: function showMoreStepsText() {
            if (this.shouldShowViewAllSteps()) {
                return this.$translate.instant('WORKFLOW.LABELS._WORKSPACE_AUTOMATION_VIEW_ALL');
            }
            var translation = this.$translate.instant('WORKFLOW.LABELS._WORKSPACE_AUTOMATION_VIEW_MORE').split(',');

            var view = translation[0];
            var moreSteps = translation[1];
            var pluralSuffix = translation[2];

            var number = this.futureWorkflowSteps.length - this.stepShowLimit;
            number = number > this.stepShowLimit ? this.stepShowLimit : number;

            moreSteps += number > 1 ? pluralSuffix : '';

            return view + number + moreSteps;
        },

        showMoreSteps: function showMoreSteps(event) {
            if (this.shouldShowViewAllSteps()) {
                this.viewFullViewWorkflowInstance(event);
            }
            this.stepShowLimit = this.stepShowLimit * 2;
            this.isShowMoreClicked = true;
        },

        hasMoreStepsToShow: function hasMoreStepsToShow() {
            return this.futureWorkflowSteps && this.futureWorkflowSteps.length > this.stepShowLimit;
        },

        shouldShowViewAllSteps: function shouldShowViewAllSteps() {
            return this.isShowMoreClicked && this.hasMoreStepsToShow();
        }
    });
}());
